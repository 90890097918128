/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

// window.Vue = require('vue').default;

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

// Vue.component('example-component', require('./components/ExampleComponent.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

// const app = new Vue({
//     el: '#app',
// });

$(document).ready(function () {
    $('#show_secret_key').on('click', function(e) {
        e.preventDefault();
        var showSecretKey = $('#show_secret_key');
        var secretKey = $('#secret_key');
        if(secretKey.hasClass('hide-text')) {
            secretKey.removeClass('hide-text');
            showSecretKey.text('Hide OBS URL');
        } else {
            secretKey.addClass('hide-text');
            showSecretKey.text('Show OBS URL');
        }
    });
    $('#bot_toggle').on('click', function(e) {
        var _this = $(this);
        if(_this.prop('checked')){
            _this.attr('title', 'Enabled');
        } else {
            _this.attr('title', 'Disabled');
        }
        $('#bot_toggle-form').submit();
    });
    $('#qc_toggle').on('click', function(e) {
        var _this = $(this);
        if(_this.prop('checked')){
            _this.attr('title', 'Enabled');
        } else {
            _this.attr('title', 'Disabled');
        }
        $('#qc_toggle-form').submit();
    });
    $('#so_toggle').on('click', function(e) {
        var _this = $(this);
        if(_this.prop('checked')){
            _this.attr('title', 'Enabled');
        } else {
            _this.attr('title', 'Disabled');
        }
        $('#so_toggle-form').submit();
    });
    $('#connect_twitch_submit').on('click', function(e) {
        var _this = $(this);
        var connected_twitch = $('#connect_twitch');
        var connected_twitch_val = connected_twitch.val();
        if(connected_twitch_val) {
            connected_twitch.val('false');
        } else {
            connected_twitch.val('true');
        }
        $('#connect_twitch_form').submit();
    });
});
